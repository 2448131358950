@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
}

.bg-image {
  background-image: url("image/bg-map.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  min-width: 200px;
  height: 100%;
}

.bg-image-tv {
  background-image: url("image/vintage_tv.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  min-width: 200px;
  height: 100%;
}

/* .blur {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  height: 100vh;
	width: 50%;
} */

.container {
  background-color: #3cb6d4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
}


/*
 * Base structure
 */

body {
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
}

.cover-container {
  backdrop-filter: blur(1px);
  text-shadow: 2px 2px 2px #757575;
  background-color: #00000060;

  overflow: hidden;
}


/*
 * Header
 */

.nav-masthead .nav-link {
  padding: .25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, .5);
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

.cover-text {
  font-size: 6em;
}

.maxHeightImage {
  width: 100% !important;
  height: auto !important;
  object-fit: cover !important;
  max-height: 100vh !important;
}
